<template>
  <div v-if="displayOnly && pills" class="form-control-static">
    <span
      v-for="option in selectedOptions"
      :key="option.value"
      class="label"
      :style="{ backgroundColor: option.labelColor }"
    >{{ option.label }}</span>
  </div>
  <div v-else-if="displayOnly" class="form-control-static">
    <slot name="display" :selections="selectedOptions">
      <span
        v-for="option in selectedOptions"
        :key="option.value"
        class="list-comma-separated"
      >{{ option.label }}</span>
    </slot>
  </div>
  <EnValidationFieldWrapper
    v-else
    :name="name"
    :required="required"
    :rules="optionsRules"
    :customMessages="computedErrorMessages"
    mode="eager"
    :veeOptions="veeOptions"
    :class="{ 'has-error': hasError }"
    ref="validator"
  >
    <EnOptionsDropdown
      ref="dropdown"
      :options="flatOptions"
      :selectedItems="value"
      :showDropdown="showDropdown"
      :textFilter="filter"
      :class="disabled ? 'disabled' : ''"
      @select="updateValue"
      :arrowNavigation="false"
      @close="showDropdown = false"
      :name="id || name"
    >
      <select
        ref="select"
        class="en-select-el sr-text"
        :name="name"
        :id="id || name"
        v-model="displayValue"
        :required="required"
        :disabled="disabled"
        @keydown="handleKeydown"
        :multiple="multi"
        @blur="$emit('blur')"
      >
          <EnInputSelectOption
            v-for="option in mappedOptions"
            :key="`${name}-${option.value || option.label}`"
            :name="name"
            :option="option"
          >
          </EnInputSelectOption>
      </select>
      <div
        :data-automation-click="id"
        class="form-control form-control-select" :class="useButtonDeselect ? 'chip-container flex-wrap-wrap align-center' : ''" @click="toggleFocus">
        <span v-if="selectedOptions.length === 0 || hideSelected">{{ placeholder }}</span>
        <a href="#"
          v-else-if="showButtonDeselect"
          v-for="option in selectedOptions"
          :key="option.value"
          class="chip chip-primary"
          :disabled="option.disabled"
          :class="option.disabled ? '' : 'icon-af-minus-circle'"
          @click.prevent="removeItem(option)"
          :data-automation-deselect="`${id}-${option.value}`"
        >{{ option.label }}</a>
        <span
          v-else
          v-for="option in selectedOptions"
          :key="option.label"
          class="list-comma-separated"
        >{{ option.label }}</span>
      </div>
    </EnOptionsDropdown>

    <div v-if="hasError" class="error-message">
      {{ errorMessage }}
    </div>
  </EnValidationFieldWrapper>
</template>

<script>
import EnValidationFieldWrapper from "./EnValidationFieldWrapper.vue";
import EnInputSelectOption from "./EnInputSelectOption.vue";
import EnOptionsDropdown from "./EnOptionsDropdown.vue";
import inputMixin from "../utils/input-mixin.js";
import optionsMixin from "../utils/options-mixin.js";

export default {
  name: "EnInputSelectPlus",
  components: {
    EnValidationFieldWrapper,
    EnInputSelectOption,
    EnOptionsDropdown,
  },
  mixins: [inputMixin, optionsMixin],
  props: {
    /**
     * Use v-model to bind to value of the input.
     * @model
     */
    value: {
      default: "",
      validate(val) {
        if (this.multi) {
          return typeof val === 'array'
        }
      }
    },
    /**
     * Array of option objects with label and value keys.
     * @example [{label: 'Red', value: 'red'}, {label: 'Blue', value: 'blue'}]
     */
    options: {
      type: Array,
      default() {
        return [
          {
            label: "No options passed",
            value: ""
          }
        ];
      }
    },
    /**
     * Styles display value as pills.
     */
    pills: {
      type: Boolean,
      default: false
    },
    /**
     * Adds a text filter to the options dropdown for long lists.
     */
    filter: {
      type: Boolean,
      default: false
    },
    placeholder: {
      default: "-- Select --"
    },
    /**
     * Sets the component to multiselect. Emitted value will be an array.
     */
    multi: {
      type: Boolean,
      default: false
    },
    /**
     * Shows the selected options as buttons to allow for deselecting.
     */
    useButtonDeselect: {
      type: Boolean,
      default: false
    },
    /**
     * Sets component to NOT display list of selected options
     */
    hideSelected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDropdown: false,
      hasFocus: false
    };
  },
  watch: {
    hasFocus(val) {
      if (val) {
        this.showDropdown = true;
      }
    },
    showDropdown(val) {
      if (val) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    }
  },
  computed: {
    showButtonDeselect() {
      return this.useButtonDeselect && !this.disabled;
    }
  },
  methods: {
    toggleFocus() {
      this.$refs.select.focus();
      if (!this.disabled){
        this.showDropdown = !this.showDropdown;
      }
      if (this.filter) {
        this.$nextTick(() => {
          this.$refs.dropdown.$refs.filter.focus()
        })
      }
    },
    updateValue(option, remove = false) {
      if (option.disabled) {
        return;
      }

      if (!this.multi && !remove) {
        this.$refs.select.focus();
        this.displayValue = option.value;
        this.showDropdown = false;
      } else {
        // this.$refs.select.focus();
        const valIndex = this.displayValue.indexOf(option.value)
        if (valIndex > -1) {
          this.displayValue = this.displayValue.toSpliced(valIndex, 1);
        } else {
          this.displayValue = this.displayValue.concat(option.value);
        }
      }
    },
    handleKeydown(e) {
      if (!this.showDropdown && (e.keyCode === 32 || e.key === "Enter")) {
        e.preventDefault();
        e.stopPropagation()
        this.toggleFocus();
      }
      if (e.key.includes('Arrow') && this.showDropdown) {
        e.preventDefault()
      }

    },
    removeItem(opt) {
      if(opt.disabled) {
        return;
      }
      this.updateValue(opt, true);
    }
  }
};
</script>

<style lang="scss">
.form-control-select {
  > span {
    // vertically aligns text
    line-height: 28px;
  }
}

.en-options-dropdown.disabled {
  .form-control-select {
    background-color: #EBF1F3;
    cursor: not-allowed;
  }
}

.en-select-el {
  transform: translateX(-100vw);
  opacity: 0;

  &:focus {
    + .form-control-select {
      outline: 3px solid $focus-color;
      outline-offset: 0px;
      border-color: color(white);
    }
  }
}
</style>
