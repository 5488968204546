<template>
  <div class="selects">
    <div class="display-flex form-group">
      <div class="col-xs-6">
        <label for="">Single select</label>
        <EnInputSelectPlus :options="selectOptions" v-model="singleSelect" id="SingleSelect" class="hi">

        </EnInputSelectPlus>
      </div>
      <div class="col-xs-6">
        <label for="">Emitted value</label>
        <pre>{{ singleSelect }}</pre>
      </div>
    </div>

    <div class="display-flex form-group">
      <div class="col-xs-6">
        <label for="">Multi select</label>
        <EnInputSelectPlus :options="selectOptions" v-model="multiSelect" multi>

        </EnInputSelectPlus>
      </div>
      <div class="col-xs-6">
        <label for="">Emitted value</label>
        <pre>{{ multiSelect }}</pre>
      </div>
    </div>

    <div class="display-flex form-group">
      <div class="col-xs-6">
        <label for="">Single select with filter</label>
        <EnInputSelectPlus :options="selectOptions" v-model="singleSelectFilter" filter>

        </EnInputSelectPlus>
      </div>
      <div class="col-xs-6">
        <label for="">Emitted value</label>
        <pre>{{ singleSelectFilter }}</pre>
      </div>
    </div>

    <div class="display-flex form-group">
      <div class="col-xs-6">
        <label for="">Multi select with filter</label>
        <EnInputSelectPlus :options="selectOptions" v-model="multiSelectFilter" multi filter useButtonDeselect>

        </EnInputSelectPlus>
      </div>
      <div class="col-xs-6">
        <label for="">Emitted value</label>
        <pre>{{ multiSelectFilter }}</pre>
      </div>
    </div>

    <div class="display-flex form-group">
      <div class="col-xs-6">
        <label for="">Side by side with Select</label>
        <EnInputSelectPlus :options="selectOptions" v-model="singleSelect">

        </EnInputSelectPlus>
      </div>
      <div class="col-xs-6">
        <label for="">Reg select</label>
        <EnInputSelect :options="selectOptions" v-model="singleSelect">

        </EnInputSelect>
      </div>
    </div>

    <div class="display-flex form-group">
      <div class="col-xs-6">
        <label for="">Option Groups select plus ({{ dropdownOpen ? 'open' : 'closed' }})</label>
        <EnInputSelectPlus
          ref="dropdown"
          :options="optGroupOptions"
          v-model="optGroupVal"
          filter
          @blur="onBlur"
          @open="dropdownOpen = true"
          @close="dropdownOpen = false"
        >

        </EnInputSelectPlus>
      </div>
      <div class="col-xs-6">
        <label for="">Option Groups Reg select</label>
        <EnInputSelect :options="optGroupOptions" v-model="optGroupVal">

        </EnInputSelect>
      </div>
    </div>
    
    <div class="display-flex form-group">
      <div class="col-xs-6">
        <label for="">SelectPlus with disabled option</label>
        <EnInputSelectPlus
          ref="dropdown"
          :options="optionswithDisabled"
          v-model="selecetPlusOptGroupVal"
          useButtonDeselect
          multi
        >
        </EnInputSelectPlus>
      </div>
      <div class="col-xs-6">
        <label for="">SelectPlus disabled</label>
        <EnInputSelectPlus :options="selectOptions" v-model="multiSelectFilter" disabled>
        </EnInputSelectPlus>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Selects',
  data() {
    return {
      selectOptions: [{label: 'Red', value: 'red'}, {label: 'Blue', value: 'blue'}, {label: 'Yellow', value: 'yellow'}
      , {label: 'Orange', value: 'Orange'}, {label: 'Purple', value: 'purple'}, {label: 'Pink', value: 'Pink'}, {label: 'Green', value: 'Green'}, {label: 'Teal', value: 'Teal'}
      , {label: 'Brown', value: 'Brown'}, {label: 'Black', value: 'Black'}, {label: 'Maroon', value: 'Maroon'}],
      singleSelect: '',
      singleSelectFilter: '',
      multiSelect: [],
      multiSelectFilter: [],
      inputGroupProps: {
        horizontal: true,
        showRequired: false,
        labelClass: 'col-md-3',
        inputClass: 'col-md-9'
      },
      optGroupVal: '',
      selecetPlusOptGroupVal: ['1'],
      optionswithDisabled: [
            {
              Value: '1',
              Label: 'Maryland',
              disabled: true
            },
            {
              Value: '2',
              Label: 'Virginia',
              disabled: false
            },
            {
              Value: '8',
              Label: 'California',
              disabled: false
            },
          ],
      optGroupOptions: [
        {
          Label: 'States',
          Options: [
            {
              Value: '1',
              Label: 'Maryland',
              disabled: true
            },
            {
              Value: '2',
              Label: 'Virginia'
            },
          ]
        },
        {
          Label: 'Countries',
          Options: [
            {
              Value: '3',
              Label: 'United States'
            },
            {
              Value: '4',
              Label: 'Canada'
            },
          ]
        },
      ],
      dropdownOpen: false
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    onBlur() {
      console.log('blur')
        }
  }
}
</script>

<style lang="scss">
.selects {

}
</style>
